<template>
    <div>
        <cost />
        <app-header></app-header>

        <el-main>
            <el-row>
                <el-col :span="4">
                    <el-menu
                        unique-opened
                        :default-active="$route.path"
                        router>
                        <nav-menu :navMenus="menus"></nav-menu>
                    </el-menu>
                </el-col>
                <el-col :span="20" class="console-main">
                    <!-- 路由占位符 -->
                    <router-view></router-view>
                </el-col>
            </el-row>
        </el-main>
    </div>
</template>

<script>
import appHeader from '@/view/app/Header'
import NavMenu from '@/components/Menu'
import cost from '@/view/cost/index'

const langMenu = 'page.console.menu.';

export default {
    data() {
        return {}
    },
    components: {
        appHeader,
        cost,
        NavMenu
    },
    computed: {
        menus() {
            return [
                {
                    id: '1',
                    title: this.$t(langMenu + 'page_settings'),
                    // icon: 'el-icon-plus',
                    children: [
                      {
                        id: '1-1',
                        title: this.$t(langMenu + 'menu_settings'),
                        path: '/console/menuSettings',
                      },
                      {
                        id: '1-2',
                        title: this.$t(langMenu + 'home_settings'),
                        path: '/console/homeSettings',
                      },
                      {
                        id: '1-3',
                        title: this.$t(langMenu + 'golden_sun'),
                        path: '/console/goldenSunSettings',
                      },
                      {
                        id: '1-4',
                        title: this.$t(langMenu + 'banner_settings'),
                        path: '/console/bannerSettings',
                      },
                      {
                        id: '1-5',
                        title: this.$t(langMenu + 'investor_settings'),
                        path: '/console/investorSettings',
                      },
                      {
                        id: '1-6',
                        title: this.$t(langMenu + 'technicalSupport_settings'),
                        path: '/console/technicalSupportSettings',
                      },
                      {
                        id: '1-7',
                        title: this.$t(langMenu + 'ad_settings'),
                        path: '/console/adSettings',
                      },
                    ]
                },
                // {
                //     id: '0-2',
                //     title: this.$t(langMenu + 'product_app'),
                //     path: '/console/productApp',
                //     icon: 'el-icon-plus'
                // },
                {
                  id: '2',
                  title: this.$t(langMenu + 'solution'),
                  icon: 'el-icon-setting',
                  path: '',
                  children: [
                    {
                      id: '2-1',
                      title: this.$t(langMenu + 'solution'),
                      path: '/console/solution',
                      // icon: 'el-icon-user'
                    },
                    {
                      id: '2-2',
                      title: this.$t(langMenu + 'solution_list'),
                      path: '/console/solutionList',
                      // icon: 'el-icon-user'
                    },
                  ]
                },
                {
                  id: '3',
                  title: this.$t(langMenu + 'product'),
                  icon: 'el-icon-setting',
                  path: '',
                  children: [
                    {
                      id: '3-1',
                      title: this.$t(langMenu + 'category'),
                      path: '/console/productType',
                      icon: 'el-icon-plus'
                    },
                    {
                      id: '3-2',
                      title: this.$t(langMenu + 'product'),
                      path: '/console/product',
                      icon: 'el-icon-plus'
                    },
                    {
                      id: '3-3',
                      title: this.$t(langMenu + 'product_list'),
                      path: '/console/productList',
                    },
                  ]
                },
                {
                  id: '4',
                  title: this.$t(langMenu + 'technical_support'),
                  icon: 'el-icon-setting',
                  path: '',
                  children: [
                    {
                      id: '4-1',
                      title: this.$t(langMenu + 'technical_support'),
                      path: '/console/technicalSupport',
                      icon: 'el-icon-plus'
                    },
                    {
                      id: '4-2',
                      title: this.$t(langMenu + 'technical_support_list'),
                      path: '/console/technicalSupportList',
                    },
                  ]
                },
                {
                  id: '5',
                  title: this.$t(langMenu + 'media'),
                  icon: 'el-icon-setting',
                  path: '',
                  children: [
                    {
                      id: '5-1',
                      title: this.$t(langMenu + 'media'),
                      path: '/console/media',
                      icon: 'el-icon-plus'
                    },
                    {
                      id: '5-2',
                      title: this.$t(langMenu + 'media_list'),
                      path: '/console/mediaList',
                    },
                  ]
                },
                {
                  id: '6',
                  title: this.$t(langMenu + 'investor'),
                  icon: 'el-icon-setting',
                  path: '',
                  children: [
                    {
                      id: '6-1',
                      title: this.$t(langMenu + 'investor'),
                      path: '/console/investor',
                      icon: 'el-icon-plus'
                    },
                    {
                      id: '6-2',
                      title: this.$t(langMenu + 'investor_list'),
                      path: '/console/investorList',
                    },
                  ]
                },
                {
                  id: '7',
                  title: this.$t(langMenu + 'wxCode'),
                  icon: 'el-icon-setting',
                  path: '/console/wx'
                },
                {
                  id: '8',
                  title: this.$t(langMenu + 'info'),
                  icon: 'el-icon-setting',
                  path: '/console/account'
                }


                /*{
                    id: '0',
                    title: this.$t(langMenu + 'notice'),
                    path: '/console/announcement',
                    icon: 'el-icon-message'
                },
                {
                    id: '1',
                    title: this.$t(langMenu + 'home'),
                    path: '/console/index',
                    icon: 'el-icon-menu'
                },
                {
                    id: '2',
                    title: this.$t(langMenu + 'activity'),
                    path: '/console/activity',
                    icon: 'el-icon-view'
                },
                {
                    id: '3',
                    title: this.$t(langMenu + 'add_activity'),
                    path: '/console/addActivity',
                    icon: 'el-icon-plus'
                },
                {
                    id: '4',
                    title: this.$t(langMenu + 'msg'),
                    path: '/console/message',
                    icon: 'el-icon-chat-dot-square'
                },
                {
                    title: this.$t(langMenu + 'setting'),
                    id: '5',
                    icon: 'el-icon-setting',
                    path: '',
                    children: [
                        {
                            id: '5-1',
                            title: this.$t(langMenu + 'account'),
                            path: '/console/setting/account',
                            icon: 'el-icon-user'
                        },
                        {
                            id: '5-2',
                            title: '屏蔽',
                            path: '/console/setting/filter',
                            icon: 'el-icon-close-notification'
                        },
                        {
                            id: '5-3',
                            title: '偏好设置',
                            path: '/console/setting/preference',
                            icon: 'el-icon-lock'
                        },
                    ]
                },
                {
                    title: this.$t(langMenu + 'manage'),
                    id: '6',
                    icon: 'el-icon-menu',
                    path: '',
                    children: [
                        //{
                        //    id: '6-1',
                        //    title: '选项设置',
                        //    path: '',
                        //    icon: 'el-icon-set-up',
                        //    children: [
                                {
                                    id: '6-2',
                                    title: '新地址',
                                    path: '/console/admin/choose/Region',
                                    icon: 'el-icon-add-location'
                                },
                                {
                                    id: '6-3',
                                    title: this.$t(langMenu + 'address'),
                                    path: '/console/admin/choose/address',
                                    icon: 'el-icon-add-location'
                                },
                                {
                                    id: '6-4',
                                    title: this.$t(langMenu + 'category'),
                                    path: '/console/admin/choose/category',
                                    icon: 'el-icon-collection'
                                },
                                {
                                    id: '6-5',
                                    title: this.$t(langMenu + 'images'),
                                    path: '/console/admin/choose/images',
                                    icon: 'el-icon-picture-outline'
                                },
                            //]
                        //}
                    ]
                }*/
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
#app{
    background: #f6f6f6;
}
.el-main{
    width: 1280px;
    margin: auto;
}
.console-main{
    padding: 0 20px;
}
.el-submenu .el-menu-item{
    width: 100%;
    min-width: 100%;
}
</style>